<template>
  <div>
    <div class="flix-form-group">
      <surveyType />
    </div>

    <div class="flix-form-group">
      <surveyQuestions
        v-if="$store.state.business.unsaved.survey.type === 'custom'"
        class="questions"
      />
      <surveyDefault
        v-if="$store.state.business.unsaved.survey.type === 'default'"
        class="questions"
      />
    </div>
    <div
      class="flix-form-group"
      v-if="$store.state.business.unsaved.survey.type === 'custom'"
    >
      <surveyShow />
    </div>
    <saveBtn v-if="checkData()" class="saveBtn" :callback="setSave" />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  components: {
    surveyType() {
      return import("@/components/business/survey/type.vue");
    },
    surveyQuestions() {
      return import("@/components/business/survey/questions.vue");
    },
    surveyDefault() {
      return import("@/components/business/survey/default.vue");
    },
    surveyShow() {
      return import("@/components/business/survey/show.vue");
    }
  },
  extends: def,
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$router.push({ name: "preview" });
    }
  }
};
</script>
<style lang="sass" scoped>
.questions
  margin-top: 20px
  display: block
.saveBtn
  margin-top: 10px
</style>
